import { strapiApi, strapiApiProduction } from ".";
import i18n from "../i18n";
import { tables } from "./types";

interface fetchDataProps {
  table: tables;
  id?: number;
  populate?: string;
  useProduction?: boolean;
}

export const fetchStrapiData = async (props: fetchDataProps) => {
  let queries = "";
  let firstQueryWritten = false;
  if (props.id) queries += `/${props.id}`;
  if (i18n.language) {
    queries += `${firstQueryWritten ? "&" : "?"}locale=${i18n.language}`;
    if (!firstQueryWritten) firstQueryWritten = true;
  }
  if (props.populate) {
    queries += `${firstQueryWritten ? "&" : "?"}populate=${props.populate}`;
    if (!firstQueryWritten) firstQueryWritten = true;
  }
  let result;
  if (props.useProduction) {
    result = await strapiApiProduction({
      method: "GET",
      url: `/${props.table}${queries}`,
    });
  } else {
    result = await strapiApi({
      method: "GET",
      url: `/${props.table}${queries}`,
    });
  }
  return result.data.data;
};
