import React from "react";
import MapsLocation from "../../components/mapsLocation/MapsLocation";
import { useTranslation } from "react-i18next";

const Rondleiding = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-section">
      <div className=" banner-section pattern-3 padding-tb padding-b">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4>{t("Rondleiding / Bezoek")}</h4>
              <p className="mb-1" style={{ width: "100%" }}>
                {t("Bezoek_text_1")}
              </p>
              <p className="mb-1" style={{ width: "100%" }}>
                {t("Bezoek_text_2")}
              </p>
              <p className="mb-4" style={{ width: "100%" }}>
                {t("Bezoek_text_3")}
              </p>
              <article className="contact-form-wrapper">
                <div className="contact-form">
                  <form
                    action="#"
                    method="POST"
                    id="commentform"
                    className="comment-form"
                  >
                    <input
                      type="text"
                      name="name"
                      className=""
                      placeholder={t('Naam vereniging / Organisatie *')??"organisation"}
                    />
                    <input
                      type="email"
                      name="email"
                      className=""
                      placeholder={t('Contact Persoon *')??"name person"}
                    />
                    <input
                      type="text"
                      name="name"
                      className=""
                      placeholder={t('Telefoon *')??"tel nr"}
                    />
                    <input
                      type="email"
                      name="email"
                      className=""
                      placeholder={t('Email *')??"email"}
                    />
                    <textarea
                      name="text"
                      id="role"
                      cols={30}
                      rows={9}
                      placeholder={t('Bericht *')??"message"}
                    ></textarea>
                    <button type="submit" className="lab-btn" style={{border:'3px solid white'}}>
                      <span>{t('Aanvragen')}</span>
                    </button>
                  </form>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-bottom">
        <MapsLocation />
      </div>
    </div>
  );
};

export default Rondleiding;
