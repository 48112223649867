import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <section className="error-section pt-2 pb-5">
      <div className="container">
        <div className="error-wrapper d-flex justify-content-center flex-column align-items-center">
          <div className="">
            <img
              src="../assets/images/general/error.png"
              alt="error404"
              width={400}
              height={300}
            />
          </div>
          <div className="error-content text-center mt-5">
            <h3 className="mb-4">
              {t("Oops! Deze pagina bestaat ( nog ) niet")}
            </h3>
            <a href="/" className="lab-btn">
              <span>{t("terug naar home")}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
