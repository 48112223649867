const ErrorComponent = () => {
  return (
    <div className="error-wrapper d-flex justify-content-center flex-column align-items-center">
      <div className="error-content text-center mt-0">
        <h3 className="mb-0" style={{ color: "red" }}>
          Oops! An error had occured
        </h3>
      </div>
    </div>
  );
};

export default ErrorComponent;
