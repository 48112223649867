import React from "react";
import { i1_history } from "../../api/types";
import ErrorComponent from "../../components/error/ErrorComponent";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";

const Ontstaan = () => {
  const [data, setdata] = React.useState<i1_history>();
  const {
    error,
    data: response,
  } = useQuery<i1_history, any, { attributes: i1_history }, [string, tables]>(
    [i18n.language, "i1-history"],
    () => fetchStrapiData({ table: "i1-history" })
  );
  
  const { t } = useTranslation();
  React.useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);
  if (error) return <ErrorComponent />;

  return (
    <section className="feature-section bg-ash padding-tb pattern-2">
      <div className="container">
          <div className="header-title text-start m-5">
            <h5>{t("Ontstaan")}</h5>
          </div>
            <ReactMarkdown>{data?.ontstaan ?? ""}</ReactMarkdown>
      </div>
    </section>
  );
};

export default Ontstaan;
