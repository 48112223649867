/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { i1_history } from "../../api/types";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ErrorComponent from "../error/ErrorComponent";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";

const SmallHistory = () => {
  const [data, setdata] = React.useState<i1_history>();

  const {
    error,
    data: response,
  } = useQuery<i1_history, any, { attributes: i1_history }, [string, tables]>(
    [i18n.language, "i1-history"],
    () => fetchStrapiData({ table: "i1-history" })
  );
  useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);

  if (error) return <ErrorComponent/>;

  return (
    <section className="about-section padding-tb shape-1">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12">
            <div className="lab-item">
              <div className="lab-inner">
                <div className="lab-content">
                  <div className="header-title text-start m-0">
                    <h5>{data?.pre_header ?? ""}</h5>
                    <h2 className="mb-0">{data?.header ?? ""}</h2>
                  </div>
                  <h5 className="my-4">{data?.post_header ?? ""}</h5>

                  <ReactMarkdown>{data?.small_text ?? ""}</ReactMarkdown>

                  {data?.btn_text && (
                    <a href="#" className="lab-btn mt-4">
                      {data.btn_text} <i className="icofont-heart-alt"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="lab-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <div className="img-grp">
                    <div className="about-circle-wrapper">
                      <div className="about-circle-2"></div>
                      <div className="about-circle"></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src="../assets/images/general/new_logo2.png"
                        alt="about-image"
                        style={{
                          width: "70%",
                          marginLeft: "25px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SmallHistory;
