/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

import { Routes, Route, Outlet, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import UserContextProvider from "./contexts/UserContexts";
import { ROUTES } from "./constants/routes";
import Ontstaan from "./pages/deMoskee/Ontstaan";
import Missie from "./pages/deMoskee/Missie";
import NieuwGebouw from "./pages/deMoskee/NieuwGebouw";
import Rondleiding from "./pages/deMoskee/Rondleiding";
import Mediatheek from "./pages/deMoskee/Mediatheek";
import DeVijfZuilen from "./pages/islam/DeVijfZuilen";
import Ramadan from "./pages/islam/Ramadan";
import Vrijdagsgebed from "./pages/islam/Vrijdagsgebed";
import Evenementen from "./pages/planning/Evenementen";
import Donatie from "./pages/Donatie";
import Login from "./pages/account/Login";
import Gebedstijden from "./pages/gebedstijden/Gebedstijden";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function Layout() {
  return (
    <React.Fragment>
      {/* <Loader /> */}
      <Header />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 15, //15 mins stale time
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <UserContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path={ROUTES.DE_MOSKEE.ONSTAAN} element={<Ontstaan />} />
                <Route path={ROUTES.DE_MOSKEE.MISSIE} element={<Missie />} />
                <Route
                  path={ROUTES.DE_MOSKEE.NIEUW_GEBOUW}
                  element={<NieuwGebouw />}
                />
                <Route
                  path={ROUTES.DE_MOSKEE.RONDLEIDING}
                  element={<Rondleiding />}
                />
                <Route
                  path={ROUTES.DE_MOSKEE.MEDIATHEEK}
                  element={<Mediatheek />}
                />
                <Route
                  path={ROUTES.ISLAM.DE_VIJF_ZUILEN}
                  element={<DeVijfZuilen />}
                />
                <Route path={ROUTES.ISLAM.RAMADAN} element={<Ramadan />} />
                <Route
                  path={ROUTES.ISLAM.VRIJDAGSGEBED}
                  element={<Vrijdagsgebed />}
                />
                <Route
                  path={ROUTES.PLANNING.EVENEMENTEN}
                  element={<Evenementen />}
                />
             
                <Route path={ROUTES.DONATIE} element={<Donatie />} />
                <Route path={ROUTES.ACCOUNT.INDEX} element={<Login />} />
                <Route path={ROUTES.GEBEDSTIJDEN} element={<Gebedstijden />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </UserContextProvider>
      </I18nextProvider>
      <ReactQueryDevtools position="bottom-left" initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
