/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div className="App">
      <App />

    <a href="" className="scrollToTop">
      <i className="icofont-bubble-up"></i>
      <span className="pluse_1"></span>
      <span className="pluse_2"></span>
    </a>

    <script src="../assets/js/jquery.js"></script>
    <script src="../assets/js/fontawesome.min.js"></script>
    <script src="../assets/js/waypoints.min.js"></script>
    <script src="../assets/js/bootstrap.bundle.min.js"></script>
    <script src="../assets/js/swiper.min.js"></script>
    <script src="../assets/js/circularProgressBar.min.js"></script>
    <script src="../assets/js/isotope.pkgd.min.js"></script>
    <script src="../assets/js/lightcase.js"></script>
    <script src="../assets/js/functions.js"></script>
  </div>
);
