// context/todoContext.tsx
import * as React from "react";
import i18n from "../i18n";
import { useQueryClient } from "@tanstack/react-query";
import { tables } from "../api/types";
import { fetchStrapiData } from "../api/calls";
import { useIsFetching } from "@tanstack/react-query";
import Loader from "../components/loader/Loader";

type possibleLanguages = "nl" | "fr" | "ar";

interface IUserContext {
  language?: string;
  changeLanguage?: (lng: possibleLanguages) => void;
}

export const UserContext = React.createContext<IUserContext>({});

interface Props {
  children: React.ReactNode;
}

const UserContextProvider: React.FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching({
    predicate: (query) => query.state.status === "loading",
  });
  const [language, SetLanguage] = React.useState<string>(i18n.language);

  const changeLanguage = (newLang: possibleLanguages) => {
    i18n.changeLanguage(newLang);
    SetLanguage(newLang);
    fetchData(newLang);
  };

  const fetchData = (lang?: possibleLanguages) => {
    queryClient.prefetchQuery<any, any, any, [string, tables]>({
      queryKey: [lang ?? i18n.language, "i1-welcome-text"],
      queryFn: () => fetchStrapiData({ table: "i1-welcome-text" }),
    });
    queryClient.prefetchQuery<any, any, any, [string, tables]>({
      queryKey: [lang ?? i18n.language, "i1-general-info"],
      queryFn: () => fetchStrapiData({ table: "i1-general-info" }),
    });
    queryClient.prefetchQuery<any, any, any, [string, tables]>({
      queryKey: [lang ?? i18n.language, "i1-the-5-pillar"],
      queryFn: () => fetchStrapiData({ table: "i1-the-5-pillar" }),
    });
    queryClient.prefetchQuery<any, any, any, [string, tables]>({
      queryKey: [lang ?? i18n.language, "i1-history"],
      queryFn: () => fetchStrapiData({ table: "i1-history" }),
    });
    queryClient.prefetchQuery<any, any, any, [string, tables]>({
      queryKey: [lang ?? i18n.language, "i1-quotes"],
      queryFn: () => fetchStrapiData({ table: "i1-quotes" }),
    });
    queryClient.prefetchQuery<any, any, any, [string, tables]>({
      queryKey: [lang ?? i18n.language, "i1-the-4-service"],
      queryFn: () => fetchStrapiData({ table: "i1-the-4-service" }),
    });
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    console.log("isFetching", isFetching);
  }, [isFetching]);

  return (
    <UserContext.Provider value={{ language, changeLanguage }}>
      <div
        style={language === "ar" ? { direction: "rtl" } : { direction: "ltr" }}
      >
        {isFetching>0 && <Loader/>}
        {children}
      </div>
    </UserContext.Provider>
  );
};

export default UserContextProvider;
