/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useTranslation } from "react-i18next";

const BlogPostOverview = () => {
  const { t } = useTranslation();
  return (
    <section className="service-section padding-tb padding-b shape-2">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header-title">
              <h5>{t("Komende evenementen")}</h5>
              <h2>{t("Inschrijven is verplicht")}</h2>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-0 justify-content-center service-wrapper">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="lab-item service-item">
                  <div className="lab-inner">
                    <div className="lab-thumb" style={{ height: "200px" }}>
                      <img
                        src="../assets/images/general/event_1.png"
                        alt="Service-image"
                      />
                    </div>

                    <div className="lab-content pattern-2">
                      <div className="lab-content-wrapper">
                        <div className="content-top">
                          <div className="service-top-content">
                            <span>26 december 12u30</span>
                            <h5>
                              <a href="#"> PIZZADAG</a>
                            </h5>
                          </div>
                        </div>
                        <div className="content-bottom">
                          <p>
                            {t('Gezellig samen eten maken, gevolgd door een quiz!')}
                          </p>
                          <a href="#" className="text-btn">
                            {t("Meer info")} +
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="lab-item service-item">
                  <div className="lab-inner">
                    <div className="lab-thumb" style={{ height: "200px" }}>
                      <img
                        src="../assets/images/general/event_2.png"
                        alt="Service-image"
                        width={312}
                      />
                    </div>
                    <div className="lab-content pattern-2">
                      <div className="lab-content-wrapper">
                        <div className="content-top">
                          <div className="service-top-content">
                            <span>26 December -&gt; 8 Januarie</span>
                            <h5>
                              <a href="#">{t('Blok in the mosque')}</a>{" "}
                            </h5>
                          </div>
                        </div>
                        <div className="content-bottom">
                          <p>
                            {t('Tussen 26 DEC en 8 JAN wordt er een aparte ruimte voorzien voor de blokkers')}
                          </p>
                          <a href="#" className="text-btn">
                            {t("Meer info")} +
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="lab-item service-item">
                  <div className="lab-inner">
                    <div className="lab-thumb" style={{ height: "200px" }}>
                      <img
                        src="../assets/images/general/event_3.png"
                        alt="Service-image"
                      />
                    </div>
                    <div className="lab-content pattern-2">
                      <div className="lab-content-wrapper">
                        <div className="content-top">
                          <div className="service-top-content">
                            <span>24 december 13u45</span>
                            <h5>
                              <a href="#">Kinder verhaaltjes</a>{" "}
                            </h5>
                          </div>
                        </div>
                        <div className="content-bottom">
                          <p>
                            {t('Een leuke namiddag voor de kleintjes vol met fantastische verhaaljes')}
                          </p>
                          <a href="#" className="text-btn">
                            {t("Meer info")} +
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPostOverview;
