import React from "react";
import WelcomeText from "../components/homeSections/WelcomeText";
import SmallHistory from "../components/homeSections/SmallHistory";
import TheFourServices from "../components/homeSections/TheFourServices";
import BlogPostOverview from "../components/blogPostOverview/BlogPostOverview";
import PillarsIslam from "../components/pillarsIslam/PillarsIslam";
import QuotesIslam from "../components/quotes/QuotesIslam";

const HomePage = () => {
  return (
    <React.Fragment>
      <WelcomeText />
      <SmallHistory />
      <TheFourServices />
      <BlogPostOverview />
      <PillarsIslam fullDisplay={false}/>
      <QuotesIslam />
    </React.Fragment>
  );
};

export default HomePage;
