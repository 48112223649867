/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useTranslation } from "react-i18next";
import { i1_the_5_pillars } from "../../api/types";
import ErrorComponent from "../error/ErrorComponent";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";

const PillarsIslam = ({ fullDisplay }: { fullDisplay?: boolean }) => {
  const { t } = useTranslation();
  const [data, setdata] = React.useState<i1_the_5_pillars>();
  const { error, data: response } = useQuery<
    i1_the_5_pillars,
    any,
    { attributes: i1_the_5_pillars },
    [string, tables]
  >([i18n.language, "i1-the-5-pillar"], () =>
    fetchStrapiData({ table: "i1-the-5-pillar"})
  );
  React.useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);

  if (error) return <ErrorComponent />;
  return (
    <React.Fragment>
      <section className="faith-section padding-tb shape-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header-title">
                <h5>{data?.small_title ?? ""}</h5>
                <h2>{data?.main_title ?? ""}</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="faith-content">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="shahadah"
                    role="tabpanel"
                    aria-labelledby="sahadah-tab"
                  >
                    <div className="lab-item faith-item tri-shape-1 pattern-2">
                      <div className="lab-inner d-flex align-items-center">
                        <div className="lab-thumb">
                          <img
                            src="../assets/images/faith/01.png"
                            alt="faith-image"
                          />
                        </div>
                        {fullDisplay ? (
                          <div className="lab-content text-center">
                            <ReactMarkdown>
                              {data?.pillar_1_main_text ?? ""}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <div className="lab-content">
                            <h4>
                              {data?.pillar_1_main_title ?? ""}
                              <span> {data?.pillar_1_sub_title ?? ""}</span>
                            </h4>
                            <p>{data?.pillar_1_small_text ?? ""}</p>
                            <a href="#" className="text-btn">
                              {t("Meer info")} +
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="prayer"
                    role="tabpanel"
                    aria-labelledby="salah-tab"
                  >
                    <div className="lab-item faith-item tri-shape-1 pattern-2">
                      <div className="lab-inner d-flex align-items-center">
                        <div className="lab-thumb">
                          <img
                            src="../assets/images/faith/02.png"
                            alt="faith-image"
                          />
                        </div>
                        {fullDisplay ? (
                          <div className="lab-content text-center">
                            <ReactMarkdown>
                              {data?.pillar_2_main_text ?? ""}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <div className="lab-content">
                            <h4>
                              {data?.pillar_2_main_title ?? ""}
                              <span> {data?.pillar_2_sub_title ?? ""}</span>
                            </h4>
                            <p>{data?.pillar_2_small_text ?? ""}</p>
                            <a href="#" className="text-btn">
                              {t("Meer info")} +
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="ramadan"
                    role="tabpanel"
                    aria-labelledby="sawm-tab"
                  >
                    <div className="lab-item faith-item tri-shape-1 pattern-2">
                      <div className="lab-inner d-flex align-items-center">
                        <div className="lab-thumb">
                          <img
                            src="../assets/images/faith/03.png"
                            alt="faith-image"
                          />
                        </div>

                        {fullDisplay ? (
                          <div className="lab-content text-center">
                            <ReactMarkdown>
                              {data?.pillar_3_main_text ?? ""}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <div className="lab-content">
                            <h4>
                              {data?.pillar_3_main_title ?? ""}
                              <span> {data?.pillar_3_sub_title ?? ""}</span>
                            </h4>
                            <p>{data?.pillar_3_small_text ?? ""}</p>
                            <a href="#" className="text-btn">
                              {t("Meer info")} +
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="jakat"
                    role="tabpanel"
                    aria-labelledby="zakat-tab"
                  >
                    <div className="lab-item faith-item tri-shape-1 pattern-2">
                      <div className="lab-inner d-flex align-items-center">
                        <div className="lab-thumb">
                          <img
                            src="../assets/images/faith/04.png"
                            alt="faith-image"
                          />
                        </div>
                        {fullDisplay ? (
                          <div className="lab-content text-center">
                            <ReactMarkdown>
                              {data?.pillar_4_main_text ?? ""}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <div className="lab-content">
                            <h4>
                              {data?.pillar_4_main_title ?? ""}
                              <span> {data?.pillar_4_sub_title ?? ""}</span>
                            </h4>
                            <p>{data?.pillar_4_small_text ?? ""}</p>
                            <a href="#" className="text-btn">
                              {t("Meer info")} +
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="hajj"
                    role="tabpanel"
                    aria-labelledby="hajj-tab"
                  >
                    <div className="lab-item faith-item tri-shape-1 pattern-2">
                      <div className="lab-inner d-flex align-items-center">
                        <div className="lab-thumb">
                          <img
                            src="../assets/images/faith/05.png"
                            alt="faith-image"
                          />
                        </div>
                        {fullDisplay ? (
                          <div className="lab-content text-center">
                            <ReactMarkdown>
                              {data?.pillar_5_main_text ?? ""}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          <div className="lab-content">
                            <h4>
                              {data?.pillar_5_main_title ?? ""}
                              <span> {data?.pillar_5_sub_title ?? ""}</span>
                            </h4>
                            <p>{data?.pillar_5_small_text ?? ""}</p>
                            <a href="#" className="text-btn">
                              {t("Meer info")} +
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <ul
                  className="nav nav-pills mb-3 align-items-center justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="sahadah-tab"
                      data-bs-toggle="pill"
                      href="#shahadah"
                      role="tab"
                      aria-controls="sahadah-tab"
                      aria-selected="true"
                    >
                      <img
                        src="../assets/images/faith/faith-icons/01.png"
                        alt="faith-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="salah-tab"
                      data-bs-toggle="pill"
                      href="#prayer"
                      role="tab"
                      aria-controls="salah-tab"
                      aria-selected="false"
                    >
                      <img
                        src="../assets/images/faith/faith-icons/02.png"
                        alt="faith-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="sawm-tab"
                      data-bs-toggle="pill"
                      href="#ramadan"
                      role="tab"
                      aria-controls="sawm-tab"
                      aria-selected="false"
                    >
                      <img
                        src="../assets/images/faith/faith-icons/03.png"
                        alt="faith-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="zakat-tab"
                      data-bs-toggle="pill"
                      href="#jakat"
                      role="tab"
                      aria-controls="zakat-tab"
                      aria-selected="false"
                    >
                      <img
                        src="../assets/images/faith/faith-icons/04.png"
                        alt="faith-icon"
                      />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="hajj-tab"
                      data-bs-toggle="pill"
                      href="#hajj"
                      role="tab"
                      aria-controls="hajj-tab"
                      aria-selected="false"
                    >
                      <img
                        src="../assets/images/faith/faith-icons/05.png"
                        alt="faith-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PillarsIslam;
