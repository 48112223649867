/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { i1_welcome_text } from "../../api/types";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ErrorComponent from "../error/ErrorComponent";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";

const WelcomeText = () => {
  const [data, setdata] = React.useState<i1_welcome_text>();
  const { error, data: response } = useQuery<
    any,
    any,
    { attributes: i1_welcome_text },
    [string, tables]
  >([i18n.language, "i1-welcome-text"], () =>
    fetchStrapiData({ table: "i1-welcome-text" })
  );
  useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);

  if (error) return <ErrorComponent />;

  return (
    <section className="banner-section pattern-2">
      <div className="container">
        <div className="row align-items-center flex-column-reverse flex-md-row">
          <div className="col-md-4">
            <div className="banner-item">
              <div className="banner-inner">
                <div className="banner-thumb">
                  <img
                    src="../assets/images/general/mosk_logo-removebg-preview.png"
                    alt="Banner-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="banner-item" style={{ width: "100%" }}>
              <div className="banner-inner" style={{ width: "100%" }}>
                <div
                  className="banner-content align-middle"
                  style={{ width: "100%" }}
                  key={data?.title}
                >
                  <h3 style={{ marginBottom: "0px" }}>{data?.title ?? ""}</h3>{" "}
                  <i> {data?.subtitle ?? ""}</i>
                  <ReactMarkdown>{data?.main_text ?? ""}</ReactMarkdown>
                  {data?.btn_text && (
                    <a href="#" className="lab-btn mt-3">
                      {data.btn_text} <i className="icofont-heart-alt"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeText;
