/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { UserContext } from "../../contexts/UserContexts";
import { useContext } from "react";

const Header = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useContext(UserContext);

  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    function UpdateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", UpdateSize);
    UpdateSize();
    return () => {
      window.removeEventListener("resize", UpdateSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="header-3 pattern-1">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-2 col-12">
            <div className="mobile-menu-wrapper d-flex flex-wrap align-items-center justify-content-between">
              <div
                className="d-lg-none"
                style={{
                  position: "absolute",
                  border: "2px solid #f6f6f6",
                  backgroundColor: "#f6f6f6",
                  borderRadius: "10px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="header-bar" style={{ maxWidth: "40px" }}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="logo" style={{ padding: "10px 0px 0px 0px" }}>
                <a>
                  <img
                    src="../assets/images/general/new_logo.png"
                    alt="logo"
                    width={"175px"}
                    height={"50px"}
                  />
                </a>
              </div>
              <div
                className="d-lg-none"
                style={{
                  position: "absolute",
                  right: "10px",
                  border: "2px solid #f6f6f6",
                  backgroundColor: "#f6f6f6",
                  borderRadius: "10px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: "100%",
                    width: "40px",
                  }}
                >
                  <select
                    style={{
                      backgroundColor: "transparent",
                      border: "0px",
                      color: "inherit",
                      fontWeight: "bold",
                      width: "40px",
                      height: "35px",
                      appearance:'none',
                      textAlign:'center'
                    }}
                    onChange={(e) =>
                      changeLanguage &&
                      changeLanguage(e.target.value as "fr" | "nl" | "ar")
                    }
                  >
                    <option value={"nl"}>NL</option>
                    <option value={"fr"}>FR</option>
                    <option value={"ar"}>AR</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-12">
            <div className="header-top">
              <div className="header-top-area">
                <ul className="left lab-ul"></ul>
                <ul className="social-icons lab-ul d-flex">
                  <li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "100%",
                        width: "140px",
                      }}
                    >
                      <span style={{ fontWeight: "bolder" }}>
                        {t("Taal")} :{" "}
                      </span>
                      <select
                        style={{
                          backgroundColor: "transparent",
                          border: "0px",
                          color: "inherit",
                          fontWeight: "bolder",
                          width: "50px",
                          height: "35px",
                        }}
                        onChange={(e) =>
                          changeLanguage &&
                          changeLanguage(e.target.value as "fr" | "nl" | "ar")
                        }
                      >
                        <option value={"nl"} style={{color:'black'}}>NL</option>
                        <option value={"fr"} style={{color:'black'}}>FR</option>
                        <option value={"ar"} style={{color:'black'}}>AR</option>
                      </select>
                    </div>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/atakwakortrijk"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/atakwakortrijk"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/atakwakortrijk"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-bottom">
              <div className="header-wrapper">
                <div className="menu-area justify-content-between w-100">
                  <ul
                    className="menu lab-ul"
                    style={{
                      color: width >= 990 ? "white" : "black",
                      fontWeight: "bold",
                    }}
                  >
                    <li>
                      <Link to={ROUTES.HOME}>{t("Home")}</Link>
                    </li>
                    <li>
                      <a
                        style={{
                          cursor: "pointer",
                          color: width >= 990 ? "white" : "black",
                        }}
                      >
                        {t("Moskee")}
                      </a>

                      <ul className="submenu">
                        <li>
                          <Link to={ROUTES.DE_MOSKEE.ONSTAAN}>
                            {t("Ontstaan")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.DE_MOSKEE.MISSIE}>
                            {t("Missie")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.DE_MOSKEE.NIEUW_GEBOUW}>
                            {t("Nieuw Gebouw")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.DE_MOSKEE.RONDLEIDING}>
                            {t("Rondleiding")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.DE_MOSKEE.MEDIATHEEK}>
                            {t("Mediatheek")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        style={{
                          cursor: "pointer",
                          color: width >= 990 ? "white" : "black",
                        }}
                      >
                        {t("Islam")}
                      </a>

                      <ul className="submenu">
                        <li>
                          <Link to={ROUTES.ISLAM.DE_VIJF_ZUILEN}>
                            {t("De Vijf Zuilen")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.ISLAM.RAMADAN}>{t("Ramadan")}</Link>
                        </li>
                        <li>
                          <Link to={ROUTES.ISLAM.VRIJDAGSGEBED}>
                            {t("Vrijdagsgebed")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={ROUTES.PLANNING.EVENEMENTEN}>
                        {t("Evenementen")}
                      </Link>
                    </li>

                    <li>
                      <Link to={ROUTES.DONATIE}>{t("Donatie")}</Link>
                    </li>
                    <li className="d-lg-none">
                      <Link to={ROUTES.GEBEDSTIJDEN}>{t("Gebedstijden")}</Link>
                    </li>
                    {/* <li>
                      <Link to={"/account"}>
                        <i
                          className="fa fa-user-alt"
                          style={{
                            color: "#fff",
                            display: width < 990 ? "none" : "",
                          }}
                        ></i>
                        {t("Inloggen")}
                      </Link>
                    </li> */}
                  </ul>
                  <div className="prayer-time d-none d-lg-block">
                    <Link to={ROUTES.GEBEDSTIJDEN} className="prayer-time-btn">
                      <i className="icofont-clock-time"></i> {t("Gebedstijden")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
