import React from "react";
import { i1_media_libraries } from "../../api/types";
import { Card, Col, Image, Row } from "antd";
const PicturesRow = ({
  event,
}: {
  event: {
    attributes: i1_media_libraries;
    id: number;
  };
}) => {
  return (
    <div style={{ width: "100%", marginTop: "5px", marginBottom: "15px" }}>
      <Card>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <h6>{event.attributes.main_title ?? ""}</h6>
            <p>{event.attributes.extra_text ?? ""}</p>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {event?.attributes?.images?.data?.slice(0, 4).map((data) => (
            <Image
              key={data.id}
              style={{ aspectRatio: "3/2", minHeight: "80%" }}
              src={data.attributes.formats.thumbnail.url}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default PicturesRow;
