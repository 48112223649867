import "./loader.scss";
const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      
      </div>
      <img
          src="../../assets/images/general/new_logo.png"
          alt="logo"
        />
    </div>
  );
};

export default Loader;
