import React from 'react'
import { i1_general_info } from '../../api/types';
import { useTranslation } from 'react-i18next';
import ErrorComponent from '../../components/error/ErrorComponent';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";

const Vrijdagsgebed = () => {
  const [data, setdata] = React.useState<i1_general_info>();
  const {
    error,
    data: response,
  } = useQuery<i1_general_info, any, { attributes: i1_general_info }, [string, tables]>(
    [i18n.language, 'i1-general-info'],
    () => fetchStrapiData({ table:"i1-general-info" })
  );  const { t } = useTranslation();

  React.useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);
  if (error) return <ErrorComponent />;

  return (
    <section className="feature-section bg-ash padding-tb pattern-2">
      <div className="container">
        <div className="header-title text-start m-5">
          <h5>{t("Vrijdagsgebed")}</h5>
        </div>
        <ReactMarkdown>{data?.vrijdagsgebed?? ""}</ReactMarkdown>
      </div>
    </section>
  );
}

export default Vrijdagsgebed