import { DatePicker, Button, Table, Switch } from "antd";
import { useState } from "react";
import { fetchMonthProps, useAladhan } from "../../api/useAladhan";
import { single_day_prayer_object } from "../../api/types";
import "./table.scss";
import { useTranslation } from "react-i18next";
import {
  CheckOutlined,
  CloseOutlined,
  FilePdfOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDocgen } from "../../api/useDocgen";

const Gebedstijden = () => {
  const { t } = useTranslation();

  const [Fajr, setFajr] = useState<boolean>(true);
  const [Sunrise, setSunrise] = useState<boolean>(true);
  const [Dhuhr, setDhuhr] = useState<boolean>(true);
  const [Asr, setAsr] = useState<boolean>(true);
  const [Maghrib, setMaghrib] = useState<boolean>(true);
  const [Isha, setIsha] = useState<boolean>(true);
  const [Midnight, setMidnight] = useState<boolean>(false);

  const [month, setMonth] = useState<string>();
  const [year, setYear] = useState<string>();

  const [searchObject, setSearchObject] = useState<fetchMonthProps>({
    month: undefined,
    year: undefined,
  });
  const { response, loading } = useAladhan(searchObject);
  const searchCalendar = () => {
    setSearchObject({ month, year });
  };
  const [docGenData, setdocGenData] = useState<any>(undefined);
  const {  loading: DocGenLoading } = useDocgen(docGenData);

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  return (
    <section className=" event-section shape-4">
      <div className="row">
        <div className="col-sm-12 col-lg-3">
          <div className="styledConfig">
            <div className="row">
              <div className="col-6 rightAlign">{t("Kies Maand:")}</div>
              <div className="col-6 leftAlign">
                <DatePicker
                  picker="month"
                  onChange={(e) => {
                    if (!e) {
                      setMonth(undefined);
                      setYear(undefined);
                    } else {
                      setMonth(`0${e.month() + 1}`.slice(-2));
                      setYear(`${e.year()}`.slice(-2));
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Fajr")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setFajr}
                  defaultChecked={Fajr}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Sunrise")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setSunrise}
                  defaultChecked={Sunrise}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Dhuhr")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setDhuhr}
                  defaultChecked={Dhuhr}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Asr")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setAsr}
                  defaultChecked={Asr}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Maghrib")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setMaghrib}
                  defaultChecked={Maghrib}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Isha")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setIsha}
                  defaultChecked={Isha}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 rightAlign">{t("Midnight")}</div>
              <div className="col-6 leftAlign">
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={setMidnight}
                  defaultChecked={Midnight}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Button
                  onMouseOver={() => {
                    if (!month && !year) setShowErrorMessage(true);
                  }}
                  onMouseOut={() => {
                    setShowErrorMessage(false);
                  }}
                  disabled={!month && !year}
                  onClick={searchCalendar}
                  className="searchBtnStyle"
                >
                  <SearchOutlined />
                  Search
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  onMouseOver={() => {
                    if (!month && !year) setShowErrorMessage(true);
                  }}
                  onMouseOut={() => {
                    setShowErrorMessage(false);
                  }}
                  disabled={!month || !year || !response}
                  loading={DocGenLoading}
                  onClick={() =>
                    setdocGenData({
                      template: {
                        name: "/i1/gebedstijden/gebedstijden-main",
                      },
                      data: {
                        meta: {
                          Fajr: Fajr,
                          Sunrise: Sunrise,
                          Dhuhr: Dhuhr,
                          Asr: Asr,
                          Maghrib: Maghrib,
                          Isha: Isha,
                          Midnight: Midnight,
                          gregorianStart: response?.[0].date.gregorian.date,
                          gregorianEnd:
                            response?.[response.length - 1].date.gregorian.date,
                          hijiriStart:  response?.[0].date.hijri.date,
                          hijriEnd: response?.[response.length - 1].date.hijri.date,
                          timezone: response?.[0].meta.timezone,
                          location: "Lille, FR",
                        },
                        data: response,
                      },
                    })
                  }
                  className="searchBtnStyle"
                >
                  <FilePdfOutlined />
                  {t("Download PDF")}
                </Button>
              </div>
            </div>
            {!month && !year && showErrorMessage && (
              <div className="row">
                <div className="col-12">
                  <p className=" errorString">
                    {t("Please select a month first!")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-12 styledTable">
          <Table<single_day_prayer_object>
            loading={loading}
            dataSource={response}
            size="small"
            pagination={false}
          >
            <Table.Column<single_day_prayer_object>
              key="Datum"
              title="Datum"
              render={(_, a) => <b>{a.date.readable.slice(0, 6)}</b>}
            />
            {Fajr && (
              <Table.Column<single_day_prayer_object>
                key="Fajr"
                title="Fajr"
                render={(_, a) => <p>{a.timings.Fajr.slice(0, 6)}</p>}
              />
            )}
            {Sunrise && (
              <Table.Column<single_day_prayer_object>
                key="Sunrise"
                title="Sunrise"
                render={(_, a) => <p>{a.timings.Sunrise.slice(0, 6)}</p>}
              />
            )}
            {Dhuhr && (
              <Table.Column<single_day_prayer_object>
                key="Dhuhr"
                title="Dhuhr"
                render={(_, a) => <p>{a.timings.Dhuhr.slice(0, 6)}</p>}
              />
            )}
            {Asr && (
              <Table.Column<single_day_prayer_object>
                key="Asr"
                title="Asr"
                render={(_, a) => <p>{a.timings.Asr.slice(0, 6)}</p>}
              />
            )}
            {Maghrib && (
              <Table.Column<single_day_prayer_object>
                key="Maghrib"
                title="Maghrib"
                render={(_, a) => <p>{a.timings.Maghrib.slice(0, 6)}</p>}
              />
            )}
            {Isha && (
              <Table.Column<single_day_prayer_object>
                key="Isha"
                title="Isha"
                render={(_, a) => <p>{a.timings.Isha.slice(0, 6)}</p>}
              />
            )}
            {Midnight && (
              <Table.Column<single_day_prayer_object>
                key="Midnight"
                title="Midnight"
                render={(_, a) => <p>{a.timings.Midnight.slice(0, 6)}</p>}
              />
            )}
          </Table>
        </div>
      </div>
    </section>
  );
};

export default Gebedstijden;
