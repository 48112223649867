import { aladhanApi } from ".";
import { useEffect, useState } from "react";
import { single_day_prayer_object } from "./types";

export interface fetchMonthProps {
  month?: string;
  year?: string;
  latitude?: number;
  longitude?: number;
  method?: number;
}

export const useAladhan = (props: fetchMonthProps) => {
  const [response, setResponse] = useState<
    single_day_prayer_object[] | undefined
  >(undefined);
  const [prevSearchObject, setPrevSearchObject] = useState<fetchMonthProps>({
    month: undefined,
    year: undefined,
  });
  const [error, setError] = useState<any>("");
  const [loading, setloading] = useState<boolean>(false);

  const fetchData = async () => {
    console.log("fetchData hit");
    if (
      !props ||
      !props.month ||
      !props.year ||
      (props.month === prevSearchObject.month &&
        props.year === prevSearchObject.year)
    ) {
      console.log("fetchData stopped");
      console.log("props", props);
      
      return;
    }
    setPrevSearchObject({ month: props.month, year: props.year });
    console.log("fetchData continues");

    try {
      setloading(true);

      let queries = "";
      queries += `/${props.year}/${props.month}`;
      queries += `?latitude=${props?.latitude ?? "50.6330"}&longitude=${
        props?.longitude ?? "3.0586"
      }`;
      queries += `&method=${props?.method ?? "12"}`;

      const { data } = await aladhanApi({
        method: "GET",
        url: queries,
      });
      console.log(data.data)
      setResponse(data.data);
    } catch (error) {
      setError(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return { response, error, loading };
};
