import React from "react";
import { i1_quotes } from "../../api/types";
import ErrorComponent from "../error/ErrorComponent";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";
const QuotesIslam = () => {
  const [data, setdata] = React.useState<{ attributes: i1_quotes }[]>([]);
  const {
    error,
    data: response,
  } = useQuery<i1_quotes, any, { attributes: i1_quotes }[], [string, tables]>(
    [i18n.language, "i1-quotes"],
    () => fetchStrapiData({ table: "i1-quotes" })
  );
  React.useEffect(() => {
    if (response) {
      setdata(response);
    }
  }, [response]);

  if (error) return <ErrorComponent/>;
  return (
    <div className="qoute-section padding-tb" style={{height:"400px"}}>
      <div className="qoute-section-wrapper">
        <div className="qoute-overlay">
          <img
            src="../assets/images/general/mosk_img2.jpg"
            alt="cgi_mosk_img"
            style={{ minWidth: "100%", height: "100%" }}
          />
        </div>
        <div className="container">
          <div className="qoute-container">
            <div className="swiper-wrapper">
              {data?.length &&
                data.map((q) => (
                  <div
                  key={q.attributes.quote}
                    className="swiper-slide"
                    style={{ background: "rgba(0, 0, 0, 0.3)" }}
                  >
                    <div className="lab-item qoute-item">
                      <div className="lab-inner d-flex align-items-center">
                        <div className="lab-thumb">
                          <span>{q.attributes.left_word ?? "Quotes"}</span>
                          <i className="icofont-quote-left"></i>
                        </div>
                        <div className="lab-content">
                          <blockquote className="blockquote">
                            <p>
                              {q?.attributes.pre_quote ?? ""}
                              <span>{q?.attributes.quote ?? ""}</span>
                            </p>
                            <footer className="blockquote-footer bg-transparent">
                              {q?.attributes.post_quote ?? ""}
                            </footer>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesIslam;
