import { useEffect, useState } from "react";
import { docgenApi } from ".";
import fileDownload from "js-file-download";

export const useDocgen = (props: any) => {
  const [error, setError] = useState<any>("");
  const [loading, setloading] = useState<boolean>(false);
  const [prevProps, setprevProps] = useState(undefined);
  const fetchPdf = async () => {
    setloading(true);
    docgenApi({
      method: "POST",
      data: props,
      responseType: "blob",
    })
      .then((response) => {
        fileDownload(response.data, "report.pdf");
      })
      .catch((e) => {
        setError(e);
        console.log(e);
      })
      .finally(() => setloading(false));
  };
  useEffect(() => {
    if (loading) return;
    if (props && prevProps !== props.data.meta) {
      setprevProps(props.data.meta);
      fetchPdf();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return { error, loading };
};
