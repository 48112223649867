import axios from "axios";

const strapiApi = axios.create({
  timeout: 20000,
  baseURL:
    "https://cms.aedesign.be/api",
  headers: {
    Authorization:
      "bearer 2c05bb0826e68bf549e8efe07214f33eb839da9022b1eccbf3d2d0a40473c906f5134f8d0a636eb18f25bf882d3f68c3c2fa40b88eddd270509f62c51435c0d4ce263fd27ad4319c606745680197e99162d270f9b6dbbfc727cfd3bee8900a107a96b12ba10efb495276531eef87e4e3fb81b788f4b4bdf700b3a18bfb8dd055",
  },
});
const strapiApiProduction = axios.create({
  timeout: 20000,
  baseURL: "https://cms.aedesign.be/api",
  headers: {
    Authorization:
      "bearer 2c05bb0826e68bf549e8efe07214f33eb839da9022b1eccbf3d2d0a40473c906f5134f8d0a636eb18f25bf882d3f68c3c2fa40b88eddd270509f62c51435c0d4ce263fd27ad4319c606745680197e99162d270f9b6dbbfc727cfd3bee8900a107a96b12ba10efb495276531eef87e4e3fb81b788f4b4bdf700b3a18bfb8dd055",
  },
});
const aladhanApi = axios.create({
  timeout: 20000,
  baseURL: "https://api.aladhan.com/v1/calendar",
});

const docgenApi = axios.create({
  timeout: 20000,
  responseType: "blob",
  baseURL: "https://docgen.aedesign.be/api/report",
});

export { strapiApi, aladhanApi, docgenApi, strapiApiProduction };
