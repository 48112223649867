const ROUTES = {
  HOME: "/",
  DE_MOSKEE: {
    ONSTAAN: "/de_moskee/ontstaan",
    MISSIE: "/de_moskee/missie",
    NIEUW_GEBOUW: "/de_moskee/nieuw_gebouw",
    RONDLEIDING: "/de_moskee/rondleiding",
    MEDIATHEEK: "/de_moskee/mediatheek",
  },
  ISLAM: {
    DE_VIJF_ZUILEN: "/islam/de_vijf_zuilen",
    RAMADAN: "/islam/ramadan",
    VRIJDAGSGEBED: "/islam/vrijdagsgebed",
  },
  PLANNING: {
    EVENEMENTEN: "/planning/evenementen",
  },
  DONATIE: "/donatie",
  GEBEDSTIJDEN: '/gebedstijden',

  ACCOUNT: {
    INDEX: "/account",
  },
};
export { ROUTES };
