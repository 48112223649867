import React from 'react'

const MapsLocation = () => {
  return (
    <div className="contac-bottom">
          <div className="row justify-content-center g-0">
            <div className="col-12">
              <div className="location-map">
                <div id="map">
                  <iframe
                    title="google_maps"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=brugsesteenweg%2059,%20Kortrijk+(Moskee%20Atakwa)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    allowFullScreen={false}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>  )
}

export default MapsLocation