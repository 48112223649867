/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { i1_the_4_services } from "../../api/types";
import ErrorComponent from "../error/ErrorComponent";
import i18n from "../../i18n";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { tables } from "../../api/types";

const TheFourServices = () => {
  const [data, setdata] = React.useState<i1_the_4_services>();
  const {
    error,
    data: response,
  } = useQuery<i1_the_4_services, any, { attributes: i1_the_4_services }, [string, tables]>(
    [i18n.language, 'i1-the-4-service'],
    () => fetchStrapiData({ table: 'i1-the-4-service' })
  );  React.useEffect(() => {
    if (response) {
      setdata(response.attributes);
    }
  }, [response]);

  if (error) return <ErrorComponent/>;

  return (
    <section className="feature-section bg-ash padding-tb pattern-2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="lab-item feature-item text-xs-center">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img src="../assets/images/feature/01.png" alt="feature-image" />
                </div>
                <div className="lab-content">
                  <h5>{data?.title_1 ?? ""}</h5>
                  <p>{data?.text_1 ?? ""}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="lab-item feature-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img src="../assets/images/feature/02.png" alt="feature-image" />
                </div>
                <div className="lab-content">
                  <h5>{data?.title_2 ?? ""}</h5>
                  <p>{data?.text_2 ?? ""}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="lab-item feature-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img src="../assets/images/feature/03.png" alt="feature-image" />
                </div>
                <div className="lab-content">
                  <h5>{data?.title_3 ?? ""}</h5>
                  <p>{data?.text_3 ?? ""}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="lab-item feature-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img src="../assets/images/feature/04.png" alt="feature-image" />
                </div>
                <div className="lab-content">
                  <h5>{data?.title_4 ?? ""}</h5>
                  <p>{data?.text_4 ?? ""}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheFourServices;
