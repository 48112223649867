import React from "react";
import { fetchStrapiData } from "../../api/calls";
import { useQuery } from "@tanstack/react-query";
import { i1_media_libraries, tables } from "../../api/types";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../components/error/ErrorComponent";
import PicturesRow from "../../components/picturesRow/PicturesRow";

const Mediatheek = () => {
  const { t } = useTranslation();
  const [data, setdata] =
    React.useState<{ attributes: i1_media_libraries; id: number }[]>();
  const { error, data: response } = useQuery<
    any,
    any,
    { attributes: i1_media_libraries; id: number }[],
    [string, tables]
  >([i18n.language, "i1-media-libraries"], () =>
    fetchStrapiData({
      table: "i1-media-libraries",
      populate: "images",
      useProduction: true,
    })
  );
  React.useEffect(() => {
    console.log(response);
    if (response) {
      setdata(response);
    }
  }, [response]);
  if (error) return <ErrorComponent />;
  return (
    <section className="feature-section bg-ash padding-tb pattern-2">
      <div className="container">
        <div className="header-title text-start m-5">
          <h5>{t("Mediatheek")}</h5>
        </div>
        {data && data?.map((event) => <PicturesRow event={event} />)}
      </div>
    </section>
  );
};

export default Mediatheek;
