/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer
      className="footer-section"
      style={{
        backgroundImage: "url(../assets/images/bg-images/footer-bg.png)",
      }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row g-3 justify-content-center g-lg-0">
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="../assets/images/footer/footer-top/02.png"
                      alt="email-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <span>{t("Email")} : info@kortrijksemoskee.be</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="footer-top-item lab-item">
                <div className="lab-inner">
                  <div className="lab-thumb">
                    <img
                      src="../assets/images/footer/footer-top/03.png"
                      alt="location-icon"
                    />
                  </div>
                  <div className="lab-content">
                    <span>
                      {t("Adres")} : Brugsesteenweg 59, 8500 Kortrijk BE
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-middle padding-tb tri-shape-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-5 mb-lg-0">
                  <div className="fm-item-title">
                    <h5>{t("Over Ons")}</h5>
                  </div>
                  <div className="fm-item-content">
                    <div className="mb-4">
                      <p style={{ marginBottom: "0px" }}>
                        <b>{t("Oude Locatie")}</b>
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        Stasegemsestraat 136, 8500 Kortrijk
                      </p>
                    </div>
                    <div className="mb-4">
                      <p style={{ marginBottom: "0px" }}>
                        <b>{t("Nieuwe Locatie")}</b>
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        Brugsesteenweg 59, 8500 Kortrijk BE
                      </p>
                    </div>
                    <div className="mb-2">
                      <p style={{ marginBottom: "0px" }}>
                        <b>{t("Onderneming")}</b>
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        vzw Islamitische en Culturele Vereniging van Kortrijk
                      </p>
                      <p style={{ marginBottom: "0px" }}>
                        Ondernemingsnummer: 455.033.235
                      </p>
                    </div>
                    <div className="mb-0">
                      <Link to={"/"}>{t("Privacy Beleid")}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item-3 mb-5 mb-lg-0">
                  <div className="fm-item-title">
                    <h5>{t("Onze Nieuwsbrief")}</h5>
                  </div>
                  <div className="fm-item-content">
                    <p>{t("subscribe_text")}</p>
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={t("Voer email in...") ?? "Email"}
                        />
                      </div>
                      <button type="submit" className="lab-btn">
                        {t("Abonneer")} <i className="icofont-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-bottom-content text-center" >
                <p style={{ direction : "ltr" }}>
                  &copy;2023 Produced by <a href="#">ATAKWA VZW</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
